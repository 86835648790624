import React from 'react';
import type DetailModel from './model';
import { fallback, FullModal } from '../../../../utils';
import { observer } from 'mobx-react';
import { Card, Image, Space } from 'antd';
import styles from './index.less';

@observer
export default class Detail extends React.Component<{ store: DetailModel }> {
  render() {
    const { visible, parentStore, onCancel, detailData } = this?.props?.store || {};
    const { groupAvatar, detailList, anchorList, groupChatList, managerName, groupCode, groupDesc, groupName, cityName, provinceName, firstCategoryName, secondCategoryName } = detailData;
    return (
      <FullModal
        onCancel={onCancel}
        visible={visible}
        title="社群详情"
      >
        <div className={styles.content}>
          <h1 className={styles.title}>社群信息</h1>
          <Card className={styles.card}>
            <div className={styles.info}>
              <div>
                <span>社群头像：</span>
                <Image
                  width={120}
                  height={70}
                  className={styles.img}
                  src={groupAvatar}
                  fallback={fallback}
                />
              </div>
              <div>
                <span>群名称：</span>
                <span>{groupName}</span>
              </div>
              <div>
                <span>社群类别：</span>
                <span>
                  {firstCategoryName} / {secondCategoryName}
                </span>
              </div>
              <div>
                <span>社群简介：</span>
                <span>{groupDesc}</span>
              </div>

              <div>
                <span>所属区域：</span>
                <span>
                  {provinceName} / {cityName}
                </span>
              </div>
            </div>
            <div className={styles.info}>
              <div>
                <span>群价格：</span>
                <div className={styles.detailList}>
                  {detailList?.map((item) => (
                    <Space
                      className={styles.detailListItem}
                      key={item.id}
                    >
                      <span className={styles.duration}>{item.duration}</span>
                      {parentStore?.addModel?.getPriceCategoryName(item.priceCategory)}
                      <span className={styles.price}>{item.price}元</span>
                    </Space>
                  ))}
                </div>
              </div>
              <div>
                <span>群管理：</span>
                <span>{managerName}</span>
              </div>
              <div>
                <span>群二维码：</span>
                <Image
                  width={100}
                  height={60}
                  className={styles.img}
                  src={groupCode}
                  fallback={fallback}
                />
              </div>
              <div />
              <div />
            </div>
          </Card>
          <Card className={styles.card}>
            <div className={styles.info}>
              <div className={styles.chat}>
                <span>群聊天：</span>
                <span>最多4张</span>
              </div>
            </div>
            <Space className={styles.chatImg}>
              {groupChatList?.map((url) => (
                <Image
                  key={url}
                  width={160}
                  height={90}
                  className={styles.img}
                  src={url}
                  fallback={fallback}
                />
              ))}
            </Space>
          </Card>
          {anchorList?.length ? (
            <Card className={styles.card}>
              <div className={styles.info}>
                <div>
                  <span>群主播：</span>
                </div>
              </div>
              <Space className={styles.userList}>
                {anchorList?.map((_, i) => (
                  <div
                    key={_.id}
                    className={styles.user}
                  >
                    <span>{_.name}</span>
                    <span>{_.worksNum || 0} 作品</span>
                  </div>
                ))}
              </Space>
            </Card>
          ) : undefined}
        </div>
      </FullModal>
    );
  }
}
