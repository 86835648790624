import { action, observable } from 'mobx';
import type CommunityModel from '../../model';
import type { FormInstance } from 'antd';
import { message } from 'antd';
import React from 'react';
import type { BaseData } from '../../../../utils';
import { request } from '../../../../utils';
import { uniqueId } from 'lodash';

export default class AddModel {
  constructor(parentStore: CommunityModel) {
    this.parentStore = parentStore;
    // this.getHuaWeiObsConfig();
    this.getPriceCategory();
    this.queryAnchor();
  }

  @observable public visible = false;

  @observable public loading = false;

  @observable public unitOptions = [];

  @observable public huaWeiObsConfig: any = {};

  @observable public detailList = [];

  @observable public anchorList = [];

  public priceId: string;

  public getPriceCategoryName = (id): string => {
    let name = '';
    this.unitOptions.forEach((item) => {
      if (item.dictValue === id) {
        name = item.dictName;
      }
    });
    return name;
  };

  @action
  public clearPriceTemplate = () => {
    this.priceId = undefined;
    this.detailList = [];
  };

  @action
  public onSubmit = () => {
    this.formRef.current.validateFields().then(async (values) => {
      if (!this.priceId) {
        return;
      }
      const params = {
        ...values,
        priceTemplateId: this.priceId,
      };
      if (params.cityId) {
        const [provinceId, cityId] = params.cityId;
        params.provinceId = provinceId;
        params.cityId = cityId;
      }
      if (params.groupChatList) {
        params.groupChatList = params.groupChatList.map((item) => item.url);
      }
      await request({
        method: 'POST',
        url: this.editId ? '/quan/biz_social_group/manage/group/update' : '/quan/biz_social_group/manage/group/add',
        data: {
          ...params,
          id: this.editId,
        },
      });
      message.success('保存成功');
      this.setVisible();
      this.parentStore.mainSubStructureModel.onQuery();
    });
  };

  @action
  public queryAnchor = async (name?: string) => {
    const req = await request<any>({
      method: 'POST',
      url: '/quan/biz/anchor/list/page',
      data: {
        pageNum: 1,
        pageSize: 1000,
        name,
      },
    });
    this.anchorList = (req?.data?.list || [])?.map((item) => ({
      key: item.id,
      title: item.name,
      description: item.name,
    }));
  };

  @action
  public getPriceCategory = async () => {
    const req = await request<{ data: any[] }>({
      method: 'GET',
      url: '/quan/sys/dict/type',
      params: { dictType: 'price_category' },
    });
    this.unitOptions = req.data;
  };

  @action
  public getHuaWeiObsConfig = async () => {
    const req = await request<{ data: any }>({
      method: 'GET',
      url: '/file/obs/getHuaWeiObsConfig',
    });
    this.huaWeiObsConfig = req.data || {};
  };

  public formRef = React.createRef<FormInstance>();

  @action
  public setVisible = () => {
    this.visible = !this.visible;
    if (!this.visible) {
      this.editId = undefined;
      this.priceId = undefined;
      this.detailList = [];
      this.formRef.current.resetFields();
    }
  };

  @action
  public setLoading = () => {
    this.loading = !this.loading;
  };

  /**
   * 关闭
   */
  @action
  public onCancel = () => {
    this.setVisible();
  };

  @observable public editId: string;

  @action
  public onEdit = async (id: string) => {
    const req = await request<any>({
      method: 'POST',
      url: '/quan/biz_social_group/manage/group/detail',
      data: { id },
    });
    this.setVisible();
    const { cityId, provinceId, priceTemplateId, groupCode, groupChatList, firstCategoryId, secondCategoryId, managerId, groupAvatar, groupName, groupDesc } = req?.data || {};
    this.editId = id;
    this.priceId = priceTemplateId;
    const reqDetail = await request<BaseData<{ detailList: [] }>>({
      method: 'GET',
      url: '/quan/biz/price/template/detail',
      params: { id: priceTemplateId },
    });
    this.detailList = reqDetail?.data?.detailList || [];
    this.parentStore.getCategorySecond(firstCategoryId);
    this.formRef.current.setFieldsValue({
      cityId: [provinceId, cityId],
      firstCategoryId,
      secondCategoryId,
      managerId,
      groupAvatar,
      groupName,
      groupDesc,
      groupCode,
      groupChatList: groupChatList.map((item) => ({
        uid: uniqueId(),
        status: 'done',
        url: item,
      })),
    });
  };

  public parentStore: CommunityModel;
}
