// 压缩文件
import * as imageConversion from 'image-conversion';

const compressImgOrVideo = async (file: File | Blob, size?: number): Promise<File | Blob | boolean> => {
  console.log('压缩前', file, file.size / 1024 / 1024);
  try {
    const compressFile = await imageConversion.compressAccurately(file, 1024);
    console.log('压缩后', compressFile, compressFile.size);
    return compressFile;
  } catch (e) {
    console.log('压缩出错', e);
    return false;
  }
};
export default compressImgOrVideo;
