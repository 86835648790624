import type CommunityModel from '../../model';
import { action, observable } from 'mobx';
import { request } from '../../../../utils';
import type { BaseData } from '../../../../utils';

export default class DetailModel {
  constructor(parentStore: CommunityModel) {
    this.parentStore = parentStore;
  }

  @observable public detailData: any = {};

  @observable public visible = false;

  @action
  public setVisible = () => {
    this.visible = !this.visible;
  };

  /**
   * 关闭
   */
  @action
  public onCancel = () => {
    this.setVisible();
  };

  @action
  public onDetail = async (id: string) => {
    const req = await request<any>({
      method: 'POST',
      url: '/quan/biz_social_group/manage/group/detail',
      data: { id },
    });
    this.setVisible();

    this.detailData = req?.data || {};
    if (this.detailData?.priceTemplateId) {
      const reqDetail = await request<BaseData<{ detailList: [] }>>({
        method: 'GET',
        url: '/quan/biz/price/template/detail',
        params: { id: this.detailData.priceTemplateId },
      });
      this.detailData.detailList = reqDetail?.data?.detailList || [];
    }
    if (this.detailData?.id) {
      const reqAnchor = await request<BaseData<any[]>>({
        method: 'GET',
        url: '/quan/group/anchor/list/anchor',
        params: { socialGroupId: this.detailData.id },
      });
      this.detailData.anchorList = (reqAnchor?.data || [])?.map((item) => ({
        name: item.name,
        worksNum: item.worksNum,
        id: item.id,
      }));
    }
  };

  public parentStore: CommunityModel;
}
